import * as React from 'react';
import moment from 'moment';

const Notes = props => {
  return <div className="notes">{props.notes.join(', ')}</div>;
};

const ResultsTable = props => {
  const {games: unsortedGames, renderItem} = props;
  const games = [...unsortedGames];
  games.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <table className="resultsTable">
      <tbody>
        {games.map((game, ii) => {
          const {teams, date, notes} = game;
          const [away, home] = teams;
          const dateString = date
            ? moment(new Date(date)).format('MMMM D')
            : null;
          return (
            <tr className="result" key={`${away}-${home}-${ii}`}>
              <td>{renderItem(game)}</td>
              <td>
                {dateString ? (
                  <div className="dateString">{dateString}</div>
                ) : null}
                {notes ? <Notes notes={notes} /> : null}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ResultsTable;
