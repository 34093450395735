const sortHeadToHead = (a, b, aWinsAgainst, bWinsAgainst) => {
  const aBeatB = aWinsAgainst.hasOwnProperty(b);
  const bBeatA = bWinsAgainst.hasOwnProperty(a);
  if (aBeatB) {
    return bBeatA ? 0 : -1;
  }
  return bBeatA ? 1 : 0;
};

const calculateDifferential = (team, results) => {
  return results.reduce((accum, result) => {
    const {teams, score} = result;
    const indexOf = teams.indexOf(team);
    if (indexOf === -1) {
      return accum;
    }

    const scoreDiff = Math.abs(score[0] - score[1]);
    const teamWon = score[indexOf] > score[(indexOf + 1) % 2];
    return accum + (teamWon ? 1 : -1) * scoreDiff;
  }, 0);
};

const sortByPointDifferential = (a, b, results) => {
  return calculateDifferential(a, results) - calculateDifferential(b, results);
};

const getSortOrder = (results, standings) => {
  const order = Object.keys(standings);

  // Order by wins.
  order.sort((teamA, teamB) => {
    const a = standings[teamA];
    const b = standings[teamB];
    return (
      // 1. Winning percentage
      b.wins / (b.wins + b.losses) - a.wins / (a.wins + a.losses) ||
      // 2. Overall win count
      b.wins - a.wins ||
      b.pointsFor - b.pointsAgainst - (a.pointsFor - a.pointsAgainst)
    );
  });

  const mostWins = standings[order[0]].wins;
  const tiedForFirst = order.filter(team => standings[team].wins === mostWins);
  if (tiedForFirst.length === 1) {
    return order;
  }

  if (tiedForFirst.length === 2) {
    const firstPlaceTeams = [...tiedForFirst];
    firstPlaceTeams.sort((teamA, teamB) => {
      const a = standings[teamA];
      const b = standings[teamB];
      return (
        sortHeadToHead(teamA, teamB, a.winsAgainst, b.winsAgainst) ||
        sortByPointDifferential(teamA, teamB, results)
      );
    });
    return firstPlaceTeams.concat(order.slice(2));
  }

  return order;
};

export default getSortOrder;
