import * as React from 'react';

const getKingLogo = team => {
  switch (team) {
    case 'cal':
      return 'https://uploads.codesandbox.io/uploads/user/abd9aa3e-833d-4e39-b0d0-ba759d595fa5/N9HU-cal.png';
    case 'stanford':
      return 'https://uploads.codesandbox.io/uploads/user/abd9aa3e-833d-4e39-b0d0-ba759d595fa5/INpi-stanford.png';
    case 'ucla':
      return 'https://uploads.codesandbox.io/uploads/user/abd9aa3e-833d-4e39-b0d0-ba759d595fa5/yjAN-ucla.png';
    case 'usc':
      return 'https://uploads.codesandbox.io/uploads/user/abd9aa3e-833d-4e39-b0d0-ba759d595fa5/9_Bh-usc.png';
    default:
      throw new Error('Absurd! Logo not found!');
  }
};

const King = props => {
  const {team, confirmed} = props;
  return (
    <div className="king">
      <div className="kingStatus">
        {confirmed ? 'Champion' : 'Current leader'}
      </div>
      {team ? (
        <img
          src={getKingLogo(team)}
          key={team}
          alt={team}
          className="kingLogo"
        />
      ) : (
        <div>None</div>
      )}
    </div>
  );
};

export default King;
