import * as React from 'react';

import TeamName from './TeamName';

const StandingsRow = props => {
  const {team, record, anyTies} = props;
  const {wins, losses, ties, pointsFor, pointsAgainst} = record;
  return (
    <tr>
      <td>
        <TeamName team={team} />
      </td>
      <td>{wins}</td>
      <td>{losses}</td>
      {anyTies ? <td>{ties}</td> : null}
      <td>{pointsFor}</td>
      <td>{pointsAgainst}</td>
    </tr>
  );
};

const StandingsTable = props => {
  const {standings, order} = props;
  const anyTies = order.some(team => !!standings[team].ties);

  return (
    <table className="standings">
      <thead>
        <tr>
          <td className="teamName">Team</td>
          <td>W</td>
          <td>L</td>
          {anyTies ? <td>T</td> : null}
          <td>PF</td>
          <td>PA</td>
        </tr>
      </thead>
      <tbody>
        {order.map(team => (
          <StandingsRow
            team={team}
            record={standings[team]}
            key={team}
            anyTies={anyTies}
          />
        ))}
      </tbody>
    </table>
  );
};

export default StandingsTable;
