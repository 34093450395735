import * as React from 'react';

import ResultsTable from './ResultsTable';
import TeamName from './TeamName';

const renderItem = game => {
  const {teams} = game;
  const [away, home] = teams;
  return (
    <div key={`${away}-${home}`}>
      <TeamName team={away} />
      {' at '}
      <TeamName team={home} />
    </div>
  );
};

const UpcomingGames = props => {
  const {games} = props;
  return (
    <div>
      <h3>Upcoming games</h3>
      <ResultsTable renderItem={renderItem} games={games} />
    </div>
  );
};

export default UpcomingGames;
