import * as React from 'react';

import TeamName from './TeamName';
import ResultsTable from './ResultsTable';

const renderItem = game => {
  const {teams, score, neutralSite} = game;
  const [away, home] = teams;
  const [awayScore, homeScore] = score;
  const awayWinner = awayScore > homeScore;
  return (
    <div>
      <TeamName team={away} emphasized={awayWinner} /> {awayScore}
      {neutralSite ? ' vs ' : ' at '}
      <TeamName team={home} emphasized={!awayWinner} /> {homeScore}
    </div>
  );
};

const Results = props => {
  return (
    <div>
      <h3>Results</h3>
      <ResultsTable games={props.games} renderItem={renderItem} />
    </div>
  );
};

export default Results;
