import * as React from 'react';
import {Link} from 'react-router-dom';

import TeamName from './TeamName';

import generateStandings from './generateStandings';
import getSortOrder from './getSortOrder';
import scores from './scores';

const HistoryPage = props => {
  const allKings = Object.keys(scores)
    .sort()
    .reverse()
    .map(year => {
      const {results, upcoming} = scores[year];
      const standings = generateStandings(results);
      const order = getSortOrder(results, standings);
      const probablyKing = order[0];
      const {wins, losses, ties} = standings[probablyKing];
      const king = !wins && !losses && ties ? null : probablyKing;
      let record = [wins, losses];
      if (ties) {
        record.push(ties);
      }

      return (
        <tr key={year}>
          <td>
            <Link to={`/years/${year}`}>{year}</Link>
          </td>
          <td>{king ? <TeamName team={king} /> : 'None'}</td>
          <td>{king ? record.join('-') : null}</td>
          <td className="historyNotes">
            {upcoming && upcoming.length ? 'Current leader' : null}
          </td>
        </tr>
      );
    });

  return (
    <table className="history">
      <tbody>{allKings}</tbody>
    </table>
  );
};

export default HistoryPage;
