import * as React from 'react';
import {Link} from 'react-router-dom';

import StandingsTable from './StandingsTable';
import King from './King';
import UpcomingGames from './UpcomingGames';
import Results from './Results';

import generateStandings from './generateStandings';
import getSortOrder from './getSortOrder';
import scores from './scores';

const YearNav = props => {
  const {currentYear: year} = props;
  const yearNumber = parseInt(year, 10);
  const prevYear = yearNumber - 1;
  const hasPrev = scores.hasOwnProperty(prevYear);
  const nextYear = yearNumber + 1;
  const hasNext = scores.hasOwnProperty(nextYear);

  return (
    <div className="yearNav">
      {hasPrev ? (
        <Link to={`/years/${prevYear}`}>&larr; {prevYear}</Link>
      ) : null}
      {hasPrev && hasNext ? <span>&nbsp;&nbsp;</span> : null}
      {hasNext ? (
        <Link to={`/years/${nextYear}`}>{nextYear} &rarr;</Link>
      ) : null}
    </div>
  );
};

const YearPage = props => {
  const {match} = props;
  const {year} = match.params;
  const scoresForYear = scores[year];

  if (!scoresForYear || !scoresForYear.results) {
    const thisYear = new Date().getFullYear();
    return (
      <div>
        <div className="notFound">Year not found!</div>
        <Link to={`/${thisYear}`}>Return to {thisYear}</Link>
      </div>
    );
  }

  const {results = [], upcoming = []} = scoresForYear;
  const standings = generateStandings(results);
  const order = getSortOrder(results, standings);
  const probablyKing = order[0];
  const onlyTie = results.length === 1 && standings[probablyKing].ties;
  const king = onlyTie ? null : probablyKing;

  return (
    <div>
      <h2>{year}</h2>
      <YearNav currentYear={year} />
      <div className="standingsAndKing">
        <StandingsTable standings={standings} order={order} />
        <King team={king} confirmed={!upcoming.length} />
      </div>
      {upcoming.length ? <UpcomingGames games={upcoming} /> : null}
      {results.length ? <Results games={results} /> : null}
    </div>
  );
};

export default YearPage;
