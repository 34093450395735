export default {
  '1892': {
    results: [
      {
        date: '12/17/1892',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [10, 10],
        notes: ['at San Francisco, CA'],
      },
      {
        date: '3/19/1892',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [14, 10],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1893': {
    results: [
      {
        date: '11/30/1893',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [6, 6],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1894': {
    results: [
      {
        date: '11/29/1894',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [6, 0],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1895': {
    results: [
      {
        date: '11/28/1895',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [6, 6],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1896': {
    results: [
      {
        date: '11/26/1896',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [20, 0],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1897': {
    results: [
      {
        date: '11/25/1897',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [28, 0],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1898': {
    results: [
      {
        date: '11/25/1898',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [0, 22],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1899': {
    results: [
      {
        date: '11/30/1899',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [0, 30],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1900': {
    results: [
      {
        date: '11/29/1900',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [5, 0],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1901': {
    results: [
      {
        date: '11/9/1901',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [0, 2],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1902': {
    results: [
      {
        date: '11/8/1902',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [0, 16],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1903': {
    results: [
      {
        date: '11/14/1903',
        neutralSite: true,
        teams: ['stanford', 'cal'],
        score: [6, 6],
        notes: ['at San Francisco, CA'],
      },
    ],
  },
  '1904': {
    results: [
      {
        date: '11/12/1904',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [18, 0],
      },
    ],
  },
  '1905': {
    results: [
      {
        date: '11/4/1905',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [0, 16],
      },
      {
        date: '11/11/1905',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [5, 12],
      },
    ],
  },
  '1915': {
    results: [
      {
        date: '11/25/1915',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [23, 21],
      },
      {
        date: '10/23/1915',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [28, 10],
      },
    ],
  },
  '1916': {
    results: [
      {
        date: '11/4/1916',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [27, 0],
      },
    ],
  },
  '1917': {
    results: [
      {
        date: '11/29/1917',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 0],
      },
    ],
  },
  '1918': {
    results: [
      {
        date: '12/14/1918',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [33, 7],
      },
    ],
  },
  '1919': {
    results: [
      {
        date: '11/8/1919',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [14, 13],
      },
      {
        date: '11/27/1919',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 13],
      },
      {
        date: '11/22/1919',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [14, 10],
      },
    ],
  },
  '1920': {
    results: [
      {
        date: '10/16/1920',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 10],
      },
      {
        date: '11/20/1920',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 38],
      },
    ],
  },
  '1921': {
    results: [
      {
        date: '11/5/1921',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [7, 38],
      },
      {
        date: '11/19/1921',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [42, 7],
      },
    ],
  },
  '1922': {
    results: [
      {
        date: '10/28/1922',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [12, 0],
      },
      {
        date: '11/11/1922',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [6, 0],
      },
      {
        date: '11/25/1922',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [28, 0],
      },
    ],
  },
  '1923': {
    results: [
      {
        date: '11/10/1923',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [13, 7],
      },
      {
        date: '10/27/1923',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [14, 7],
      },
      {
        date: '11/24/1923',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 9],
      },
    ],
  },
  '1924': {
    results: [
      {
        date: '11/1/1924',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [0, 7],
      },
      {
        date: '11/22/1924',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [20, 20],
      },
    ],
  },
  '1925': {
    results: [
      {
        date: '10/17/1925',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [13, 9],
      },
      {
        date: '11/14/1925',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [0, 82],
      },
      {
        date: '11/21/1925',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [14, 27],
      },
    ],
  },
  '1926': {
    results: [
      {
        date: '10/23/1926',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [27, 0],
      },
      {
        date: '10/30/1926',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [13, 12],
      },
      {
        date: '11/20/1926',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [41, 6],
      },
    ],
  },
  '1927': {
    results: [
      {
        date: '10/29/1927',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 13],
      },
      {
        date: '10/15/1927',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [13, 13],
      },
      {
        date: '11/19/1927',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [6, 13],
      },
    ],
  },
  '1928': {
    results: [
      {
        date: '10/20/1928',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [0, 0],
      },
      {
        date: '11/3/1928',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 10],
      },
      {
        date: '10/13/1928',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [7, 45],
      },
      {
        date: '11/24/1928',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [13, 13],
      },
    ],
  },
  '1929': {
    results: [
      {
        date: '11/2/1929',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [15, 7],
      },
      {
        date: '9/28/1929',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [0, 76],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/26/1929',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [7, 0],
      },
      {
        date: '10/12/1929',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [57, 0],
      },
      {
        date: '11/23/1929',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [6, 21],
      },
    ],
  },
  '1930': {
    results: [
      {
        date: '11/8/1930',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 74],
      },
      {
        date: '9/27/1930',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [0, 52],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/25/1930',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [41, 12],
      },
      {
        date: '10/31/1930',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [0, 20],
      },
      {
        date: '11/22/1930',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [41, 0],
      },
    ],
  },
  '1931': {
    results: [
      {
        date: '10/24/1931',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [6, 0],
      },
      {
        date: '11/7/1931',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 19],
      },
      {
        date: '10/31/1931',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [6, 12],
      },
      {
        date: '11/21/1931',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [6, 0],
      },
    ],
  },
  '1932': {
    results: [
      {
        date: '11/5/1932',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 27],
      },
      {
        date: '10/22/1932',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [13, 0],
      },
      {
        date: '10/29/1932',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [6, 13],
      },
      {
        date: '11/19/1932',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 0],
      },
    ],
  },
  '1933': {
    results: [
      {
        date: '11/4/1933',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [0, 0],
      },
      {
        date: '10/28/1933',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [6, 3],
      },
      {
        date: '11/11/1933',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [13, 7],
      },
      {
        date: '9/30/1933',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [0, 3],
      },
      {
        date: '11/25/1933',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [3, 7],
      },
    ],
  },
  '1934': {
    results: [
      {
        date: '10/20/1934',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [0, 3],
      },
      {
        date: '11/10/1934',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 2],
      },
      {
        date: '10/27/1934',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [0, 16],
      },
      {
        date: '11/3/1934',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [27, 0],
      },
      {
        date: '11/24/1934',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [9, 7],
      },
    ],
  },
  '1935': {
    results: [
      {
        date: '11/2/1935',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [14, 2],
      },
      {
        date: '10/26/1935',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [7, 21],
      },
      {
        date: '11/9/1935',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [3, 0],
      },
      {
        date: '10/19/1935',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [7, 6],
      },
      {
        date: '11/23/1935',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [0, 13],
      },
    ],
  },
  '1936': {
    results: [
      {
        date: '10/17/1936',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [17, 6],
      },
      {
        date: '11/7/1936',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [13, 7],
      },
      {
        date: '11/26/1936',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/24/1936',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [14, 7],
      },
      {
        date: '10/31/1936',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [19, 6],
      },
      {
        date: '11/21/1936',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 20],
      },
    ],
  },
  '1937': {
    results: [
      {
        date: '10/30/1937',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 14],
      },
      {
        date: '10/23/1937',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [6, 20],
      },
      {
        date: '12/4/1937',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 19],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/6/1937',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [7, 6],
      },
      {
        date: '10/9/1937',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [7, 12],
      },
      {
        date: '11/20/1937',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [13, 0],
      },
    ],
  },
  '1938': {
    results: [
      {
        date: '10/15/1938',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [7, 20],
      },
      {
        date: '11/5/1938',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 13],
      },
      {
        date: '11/24/1938',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 42],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/22/1938',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [13, 2],
      },
      {
        date: '10/29/1938',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [0, 6],
      },
      {
        date: '11/19/1938',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 6],
      },
    ],
  },
  '1939': {
    results: [
      {
        date: '11/4/1939',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [7, 20],
      },
      {
        date: '10/28/1939',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [26, 0],
      },
      {
        date: '12/9/1939',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [0, 0],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/11/1939',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 33],
      },
      {
        date: '10/14/1939',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [14, 14],
      },
      {
        date: '11/25/1939',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [32, 14],
      },
    ],
  },
  '1940': {
    results: [
      {
        date: '10/19/1940',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [7, 9],
      },
      {
        date: '11/9/1940',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [20, 7],
      },
      {
        date: '11/30/1940',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [12, 28],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/26/1940',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [7, 21],
      },
      {
        date: '11/2/1940',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [20, 14],
      },
      {
        date: '11/30/1940',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [13, 7],
      },
    ],
  },
  '1941': {
    results: [
      {
        date: '11/1/1941',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 7],
      },
      {
        date: '10/25/1941',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [0, 14],
      },
      {
        date: '12/6/1941',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/8/1941',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [13, 0],
      },
      {
        date: '10/4/1941',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [0, 33],
      },
      {
        date: '11/29/1941',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [16, 0],
      },
    ],
  },
  '1942': {
    results: [
      {
        date: '10/17/1942',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [21, 0],
      },
      {
        date: '11/7/1942',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 21],
      },
      {
        date: '12/12/1942',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [14, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/24/1942',
        neutralSite: true,
        teams: ['stanford', 'usc'],
        score: [14, 6],
        notes: ['at San Francisco, CA'],
      },
      {
        date: '10/31/1942',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [7, 21],
      },
      {
        date: '11/21/1942',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [26, 7],
      },
    ],
  },
  '1943': {
    results: [
      {
        date: '11/13/1943',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [6, 13],
      },
      {
        date: '10/16/1943',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [13, 0],
      },
      {
        date: '10/30/1943',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 13],
      },
      {
        date: '10/2/1943',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [7, 0],
      },
      {
        date: '11/27/1943',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 26],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '9/25/1943',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [0, 20],
        notes: ['at Los Angeles, CA'],
      },
    ],
  },
  '1944': {
    results: [
      {
        date: '11/11/1944',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [0, 7],
      },
      {
        date: '9/30/1944',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [0, 6],
      },
      {
        date: '11/18/1944',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [32, 0],
      },
      {
        date: '10/7/1944',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [6, 6],
      },
      {
        date: '11/25/1944',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 40],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '9/23/1944',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 13],
        notes: ['at Los Angeles, CA'],
      },
    ],
  },
  '1945': {
    results: [
      {
        date: '11/24/1945',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [0, 6],
      },
      {
        date: '10/13/1945',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [0, 13],
      },
      {
        date: '11/10/1945',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 14],
      },
      {
        date: '9/29/1945',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [13, 2],
      },
      {
        date: '12/1/1945',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [15, 26],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '9/21/1945',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [6, 13],
        notes: ['at Los Angeles, CA'],
      },
    ],
  },
  '1946': {
    results: [
      {
        date: '10/19/1946',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [13, 6],
      },
      {
        date: '11/9/1946',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 14],
      },
      {
        date: '11/23/1946',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 6],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/26/1946',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [28, 20],
      },
      {
        date: '10/12/1946',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [6, 26],
      },
      {
        date: '11/23/1946',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [25, 6],
      },
    ],
  },
  '1947': {
    results: [
      {
        date: '11/1/1947',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [6, 0],
      },
      {
        date: '10/25/1947',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [39, 14],
      },
      {
        date: '11/22/1947',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [0, 6],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/8/1947',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 14],
      },
      {
        date: '10/18/1947',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [39, 6],
      },
      {
        date: '11/22/1947',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [21, 18],
      },
    ],
  },
  '1948': {
    results: [
      {
        date: '11/6/1948',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [13, 28],
      },
      {
        date: '10/30/1948',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [13, 7],
      },
      {
        date: '11/20/1948',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 20],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/23/1948',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [7, 6],
      },
      {
        date: '10/16/1948',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [34, 14],
      },
      {
        date: '11/20/1948',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [6, 7],
      },
    ],
  },
  '1949': {
    results: [
      {
        date: '10/29/1949',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [35, 21],
      },
      {
        date: '10/15/1949',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [10, 16],
      },
      {
        date: '11/19/1949',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 21],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/5/1949',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [34, 13],
      },
      {
        date: '10/8/1949',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [14, 7],
      },
      {
        date: '11/19/1949',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [33, 14],
      },
    ],
  },
  '1950': {
    results: [
      {
        date: '11/11/1950',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [0, 35],
      },
      {
        date: '10/14/1950',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [13, 7],
      },
      {
        date: '11/25/1950',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [39, 0],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/4/1950',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [7, 7],
      },
      {
        date: '10/21/1950',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [7, 21],
      },
      {
        date: '11/25/1950',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [7, 7],
      },
    ],
  },
  '1951': {
    results: [
      {
        date: '11/3/1951',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [7, 21],
      },
      {
        date: '10/20/1951',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [21, 14],
      },
      {
        date: '11/24/1951',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [21, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/10/1951',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [27, 20],
      },
      {
        date: '10/13/1951',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [7, 21],
      },
      {
        date: '11/24/1951',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [20, 7],
      },
    ],
  },
  '1952': {
    results: [
      {
        date: '11/1/1952',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 7],
      },
      {
        date: '10/25/1952',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 10],
      },
      {
        date: '11/22/1952',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [12, 14],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/8/1952',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [54, 7],
      },
      {
        date: '10/18/1952',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [14, 24],
      },
      {
        date: '11/22/1952',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [0, 26],
      },
    ],
  },
  '1953': {
    results: [
      {
        date: '10/31/1953',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [7, 20],
      },
      {
        date: '10/24/1953',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [32, 20],
      },
      {
        date: '11/21/1953',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 0],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/7/1953',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [20, 23],
      },
      {
        date: '10/17/1953',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [20, 21],
      },
      {
        date: '11/21/1953',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [21, 21],
      },
    ],
  },
  '1954': {
    results: [
      {
        date: '10/30/1954',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [27, 6],
      },
      {
        date: '10/23/1954',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [27, 29],
      },
      {
        date: '11/20/1954',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [34, 0],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/6/1954',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [21, 7],
      },
      {
        date: '10/16/1954',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [0, 72],
      },
      {
        date: '11/20/1954',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [20, 28],
      },
    ],
  },
  '1955': {
    results: [
      {
        date: '10/29/1955',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [0, 47],
      },
      {
        date: '10/22/1955',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [33, 6],
      },
      {
        date: '11/19/1955',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [17, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/5/1955',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [28, 20],
      },
      {
        date: '10/15/1955',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [21, 13],
      },
      {
        date: '11/19/1955',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [0, 19],
      },
    ],
  },
  '1956': {
    results: [
      {
        date: '10/20/1956',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [34, 20],
      },
      {
        date: '11/10/1956',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 20],
      },
      {
        date: '11/24/1956',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 10],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/27/1956',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [19, 27],
      },
      {
        date: '11/3/1956',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [13, 14],
      },
      {
        date: '11/24/1956',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [18, 20],
      },
    ],
  },
  '1957': {
    results: [
      {
        date: '11/2/1957',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [14, 16],
      },
      {
        date: '10/19/1957',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [0, 12],
      },
      {
        date: '11/23/1957',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [20, 9],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/9/1957',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [35, 7],
      },
      {
        date: '10/26/1957',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [6, 20],
      },
      {
        date: '11/23/1957',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [12, 14],
      },
    ],
  },
  '1958': {
    results: [
      {
        date: '11/8/1958',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [17, 20],
      },
      {
        date: '10/18/1958',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [14, 12],
      },
      {
        date: '11/22/1958',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [15, 15],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/1/1958',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [29, 6],
      },
      {
        date: '10/25/1958',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [21, 19],
      },
      {
        date: '11/22/1958',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [15, 16],
      },
    ],
  },
  '1959': {
    results: [
      {
        date: '10/17/1959',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [12, 19],
      },
      {
        date: '10/31/1959',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [14, 7],
      },
      {
        date: '11/21/1959',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [10, 3],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/24/1959',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [28, 30],
      },
      {
        date: '11/7/1959',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [55, 13],
      },
      {
        date: '11/21/1959',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [20, 17],
      },
    ],
  },
  '1960': {
    results: [
      {
        date: '11/5/1960',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 0],
      },
      {
        date: '10/15/1960',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [10, 27],
      },
      {
        date: '11/19/1960',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [6, 17],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/29/1960',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [21, 6],
      },
      {
        date: '10/22/1960',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [8, 26],
      },
      {
        date: '11/19/1960',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [10, 21],
      },
    ],
  },
  '1961': {
    results: [
      {
        date: '11/4/1961',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [15, 35],
      },
      {
        date: '10/21/1961',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [28, 14],
      },
      {
        date: '11/25/1961',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [10, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/11/1961',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [15, 30],
      },
      {
        date: '10/28/1961',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [20, 0],
      },
      {
        date: '11/25/1961',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [7, 20],
      },
    ],
  },
  '1962': {
    results: [
      {
        date: '11/3/1962',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [26, 16],
      },
      {
        date: '10/20/1962',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [6, 32],
      },
      {
        date: '11/24/1962',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [3, 14],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/10/1962',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [39, 14],
      },
      {
        date: '10/27/1962',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [17, 7],
      },
      {
        date: '11/24/1962',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [30, 13],
      },
    ],
  },
  '1963': {
    results: [
      {
        date: '11/2/1963',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [25, 0],
      },
      {
        date: '10/26/1963',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [36, 6],
      },
      {
        date: '11/30/1963',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [6, 26],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/9/1963',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [11, 25],
      },
      {
        date: '10/5/1963',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [10, 9],
      },
      {
        date: '11/30/1963',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [17, 28],
      },
    ],
  },
  '1964': {
    results: [
      {
        date: '10/31/1964',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [25, 21],
      },
      {
        date: '10/24/1964',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [21, 26],
      },
      {
        date: '11/21/1964',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 34],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/7/1964',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [15, 10],
      },
      {
        date: '10/3/1964',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [20, 27],
      },
      {
        date: '11/21/1964',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [21, 3],
      },
    ],
  },
  '1965': {
    results: [
      {
        date: '10/23/1965',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [3, 56],
      },
      {
        date: '11/6/1965',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [35, 0],
      },
      {
        date: '11/20/1965',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [20, 16],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/16/1965',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 14],
      },
      {
        date: '11/13/1965',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [30, 13],
      },
      {
        date: '11/20/1965',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [7, 9],
      },
    ],
  },
  '1966': {
    results: [
      {
        date: '10/22/1966',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 15],
      },
      {
        date: '11/5/1966',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [9, 35],
      },
      {
        date: '11/19/1966',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [14, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/15/1966',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [21, 7],
      },
      {
        date: '11/12/1966',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [0, 10],
      },
      {
        date: '11/19/1966',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [13, 7],
      },
    ],
  },
  '1967': {
    results: [
      {
        date: '10/14/1967',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [14, 37],
      },
      {
        date: '11/4/1967',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [31, 12],
      },
      {
        date: '11/18/1967',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [20, 21],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/7/1967',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 30],
      },
      {
        date: '10/21/1967',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [21, 16],
      },
      {
        date: '11/18/1967',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [26, 3],
      },
    ],
  },
  '1968': {
    results: [
      {
        date: '10/19/1968',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [15, 39],
      },
      {
        date: '11/9/1968',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [17, 35],
      },
      {
        date: '11/23/1968',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [16, 28],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/12/1968',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [27, 24],
      },
      {
        date: '10/26/1968',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [17, 20],
      },
      {
        date: '11/23/1968',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [20, 0],
      },
    ],
  },
  '1969': {
    results: [
      {
        date: '10/18/1969',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [0, 32],
      },
      {
        date: '11/1/1969',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [14, 9],
      },
      {
        date: '11/22/1969',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [12, 14],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/11/1969',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [24, 26],
      },
      {
        date: '10/25/1969',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [20, 20],
      },
      {
        date: '11/22/1969',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [28, 29],
      },
    ],
  },
  '1970': {
    results: [
      {
        date: '10/17/1970',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [24, 21],
      },
      {
        date: '10/31/1970',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [13, 10],
      },
      {
        date: '11/21/1970',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [45, 20],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/10/1970',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [14, 24],
      },
      {
        date: '10/24/1970',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [9, 7],
      },
      {
        date: '11/21/1970',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [14, 22],
      },
    ],
  },
  '1971': {
    results: [
      {
        date: '10/23/1971',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [31, 24],
      },
      {
        date: '10/30/1971',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [28, 0],
      },
      {
        date: '11/20/1971',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 7],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/16/1971',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [33, 18],
      },
      {
        date: '11/6/1971',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [9, 20],
      },
      {
        date: '11/20/1971',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [0, 14],
      },
    ],
  },
  '1972': {
    results: [
      {
        date: '10/21/1972',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [49, 13],
      },
      {
        date: '10/14/1972',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [14, 42],
      },
      {
        date: '11/18/1972',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [7, 24],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/7/1972',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [30, 21],
      },
      {
        date: '11/4/1972',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [23, 28],
      },
      {
        date: '11/18/1972',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [21, 24],
      },
    ],
  },
  '1973': {
    results: [
      {
        date: '10/27/1973',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [21, 61],
      },
      {
        date: '11/3/1973',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [50, 14],
      },
      {
        date: '11/24/1973',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [13, 23],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/10/1973',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [26, 27],
      },
      {
        date: '10/13/1973',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [59, 13],
      },
      {
        date: '11/24/1973',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [17, 26],
      },
    ],
  },
  '1974': {
    results: [
      {
        date: '10/26/1974',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 3],
      },
      {
        date: '11/2/1974',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [15, 15],
      },
      {
        date: '11/23/1974',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [9, 34],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/9/1974',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [34, 10],
      },
      {
        date: '10/12/1974',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [13, 13],
      },
      {
        date: '11/23/1974',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [22, 20],
      },
    ],
  },
  '1975': {
    results: [
      {
        date: '10/25/1975',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [14, 28],
      },
      {
        date: '11/1/1975',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [14, 28],
      },
      {
        date: '11/28/1975',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [25, 22],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/8/1975',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [13, 10],
      },
      {
        date: '10/11/1975',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [31, 21],
      },
      {
        date: '11/22/1975',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [48, 15],
      },
    ],
  },
  '1976': {
    results: [
      {
        date: '10/23/1976',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [35, 19],
      },
      {
        date: '10/30/1976',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [6, 20],
      },
      {
        date: '11/20/1976',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [14, 24],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/6/1976',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [48, 24],
      },
      {
        date: '10/9/1976',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [20, 38],
      },
      {
        date: '11/20/1976',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [27, 24],
      },
    ],
  },
  '1977': {
    results: [
      {
        date: '10/22/1977',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [19, 21],
        notes: ['UCLA Forfeits'],
      },
      {
        date: '10/29/1977',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [14, 17],
      },
      {
        date: '11/25/1977',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [27, 29],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/5/1977',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 49],
      },
      {
        date: '10/8/1977',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [28, 32],
      },
      {
        date: '11/19/1977',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [3, 21],
      },
    ],
  },
  '1978': {
    results: [
      {
        date: '10/21/1978',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [45, 0],
      },
      {
        date: '10/28/1978',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [17, 42],
      },
      {
        date: '11/18/1978',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [10, 17],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/4/1978',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [13, 7],
      },
      {
        date: '10/7/1978',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [26, 27],
      },
      {
        date: '11/18/1978',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [30, 10],
      },
    ],
  },
  '1979': {
    results: [
      {
        date: '10/20/1979',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 28],
      },
      {
        date: '10/27/1979',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [24, 14],
      },
      {
        date: '11/24/1979',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [14, 49],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/13/1979',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [21, 21],
      },
      {
        date: '10/6/1979',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [24, 27],
      },
      {
        date: '11/17/1979',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [21, 14],
      },
    ],
  },
  '1980': {
    results: [
      {
        date: '10/25/1980',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [32, 9],
      },
      {
        date: '11/1/1980',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 60],
      },
      {
        date: '11/22/1980',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [20, 17],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '11/8/1980',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [34, 9],
      },
      {
        date: '10/11/1980',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [21, 35],
      },
      {
        date: '11/22/1980',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [23, 28],
      },
    ],
  },
  '1981': {
    results: [
      {
        date: '10/24/1981',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [6, 34],
      },
      {
        date: '11/7/1981',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [21, 3],
      },
      {
        date: '11/21/1981',
        neutralSite: true,
        teams: ['ucla', 'usc'],
        score: [21, 22],
        notes: ['at Los Angeles, CA'],
      },
      {
        date: '10/17/1981',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [17, 25],
      },
      {
        date: '10/10/1981',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [23, 26],
      },
      {
        date: '11/21/1981',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [21, 42],
      },
    ],
  },
  '1982': {
    results: [
      {
        date: '10/23/1982',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [47, 31],
      },
      {
        date: '11/6/1982',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 42],
      },
      {
        date: '11/20/1982',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [19, 20],
      },
      {
        date: '10/16/1982',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [41, 21],
      },
      {
        date: '11/13/1982',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [35, 38],
      },
      {
        date: '11/20/1982',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [20, 25],
      },
    ],
  },
  '1983': {
    results: [
      {
        date: '10/22/1983',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [16, 20],
      },
      {
        date: '10/29/1983',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [19, 9],
      },
      {
        date: '11/19/1983',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [27, 17],
      },
      {
        date: '11/5/1983',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [7, 30],
      },
      {
        date: '10/8/1983',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [39, 21],
      },
      {
        date: '11/27/1983',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [27, 18],
      },
    ],
  },
  '1984': {
    results: [
      {
        date: '10/20/1984',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [17, 14],
      },
      {
        date: '10/27/1984',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [7, 31],
      },
      {
        date: '11/17/1984',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [10, 29],
      },
      {
        date: '11/3/1984',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [20, 11],
      },
      {
        date: '10/6/1984',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [23, 21],
      },
      {
        date: '11/17/1984',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [27, 10],
      },
    ],
  },
  '1985': {
    results: [
      {
        date: '10/26/1985',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [7, 34],
      },
      {
        date: '11/9/1985',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [6, 14],
      },
      {
        date: '11/23/1985',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [13, 17],
      },
      {
        date: '10/19/1985',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [6, 30],
      },
      {
        date: '10/12/1985',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [34, 9],
      },
      {
        date: '11/23/1985',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [22, 24],
      },
    ],
  },
  '1986': {
    results: [
      {
        date: '10/18/1986',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [36, 10],
      },
      {
        date: '11/15/1986',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [3, 28],
      },
      {
        date: '11/22/1986',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [25, 45],
      },
      {
        date: '10/25/1986',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [10, 0],
      },
      {
        date: '11/8/1986',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [28, 23],
      },
      {
        date: '11/22/1986',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [11, 17],
      },
    ],
  },
  '1987': {
    results: [
      {
        date: '10/24/1987',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [18, 42],
      },
      {
        date: '9/26/1987',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [31, 14],
      },
      {
        date: '11/21/1987',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [13, 17],
      },
      {
        date: '11/7/1987',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [24, 39],
      },
      {
        date: '10/3/1987',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [49, 0],
      },
      {
        date: '11/21/1987',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [7, 31],
      },
    ],
  },
  '1988': {
    results: [
      {
        date: '10/15/1988',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [38, 21],
      },
      {
        date: '11/5/1988',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [3, 35],
      },
      {
        date: '11/19/1988',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [31, 22],
      },
      {
        date: '9/10/1988',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [24, 20],
      },
      {
        date: '11/12/1988',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [17, 27],
      },
      {
        date: '11/19/1988',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [19, 19],
      },
    ],
  },
  '1989': {
    results: [
      {
        date: '9/30/1989',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [6, 24],
      },
      {
        date: '10/14/1989',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [31, 15],
      },
      {
        date: '11/18/1989',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [10, 10],
      },
      {
        date: '10/28/1989',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [0, 19],
      },
      {
        date: '11/4/1989',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [14, 17],
      },
      {
        date: '11/18/1989',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [14, 24],
      },
    ],
  },
  '1990': {
    results: [
      {
        date: '10/20/1990',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [31, 38],
      },
      {
        date: '11/3/1990',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [31, 31],
      },
      {
        date: '11/17/1990',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [45, 42],
      },
      {
        date: '10/13/1990',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [37, 22],
      },
      {
        date: '9/15/1990',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [31, 32],
      },
      {
        date: '11/17/1990',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [27, 25],
      },
    ],
  },
  '1991': {
    results: [
      {
        date: '10/5/1991',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 24],
      },
      {
        date: '11/2/1991',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [30, 52],
      },
      {
        date: '11/23/1991',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [24, 21],
      },
      {
        date: '10/19/1991',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [24, 21],
      },
      {
        date: '11/9/1991',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [10, 27],
      },
      {
        date: '11/23/1991',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [21, 38],
      },
    ],
  },
  '1992': {
    results: [
      {
        date: '10/31/1992',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [12, 48],
      },
      {
        date: '10/17/1992',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [24, 27],
      },
      {
        date: '11/21/1992',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [37, 38],
      },
      {
        date: '11/7/1992',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [9, 23],
      },
      {
        date: '10/10/1992',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [19, 7],
      },
      {
        date: '11/21/1992',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [41, 21],
      },
    ],
  },
  '1993': {
    results: [
      {
        date: '9/4/1993',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 25],
      },
      {
        date: '10/30/1993',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [42, 14],
      },
      {
        date: '11/20/1993',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [27, 21],
      },
      {
        date: '11/6/1993',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [20, 45],
      },
      {
        date: '9/25/1993',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [28, 25],
      },
      {
        date: '11/20/1993',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [46, 17],
      },
    ],
  },
  '1994': {
    results: [
      {
        date: '10/8/1994',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [7, 26],
      },
      {
        date: '10/22/1994',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [0, 61],
      },
      {
        date: '11/19/1994',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [19, 31],
      },
      {
        date: '10/15/1994',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [27, 20],
      },
      {
        date: '10/29/1994',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [30, 31],
      },
      {
        date: '11/19/1994',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [23, 24],
      },
    ],
  },
  '1995': {
    results: [
      {
        date: '10/28/1995',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [16, 33],
      },
      {
        date: '10/7/1995',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [26, 16],
      },
      {
        date: '11/18/1995',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [24, 20],
      },
      {
        date: '11/4/1995',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [30, 31],
      },
      {
        date: '10/21/1995',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [42, 28],
      },
      {
        date: '11/18/1995',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [24, 29],
      },
    ],
  },
  '1996': {
    results: [
      {
        date: '10/26/1996',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [38, 29],
      },
      {
        date: '10/5/1996',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [22, 15],
      },
      {
        date: '11/23/1996',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [41, 48],
      },
      {
        date: '11/9/1996',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [20, 24],
      },
      {
        date: '11/2/1996',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [21, 20],
      },
      {
        date: '11/23/1996',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [42, 21],
      },
    ],
  },
  '1997': {
    results: [
      {
        date: '10/25/1997',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [17, 35],
      },
      {
        date: '9/27/1997',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [27, 17],
      },
      {
        date: '11/22/1997',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [31, 24],
      },
      {
        date: '11/8/1997',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [21, 45],
      },
      {
        date: '11/1/1997',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [27, 7],
      },
      {
        date: '11/22/1997',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [20, 21],
      },
    ],
  },
  '1998': {
    results: [
      {
        date: '10/24/1998',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 16],
      },
      {
        date: '10/10/1998',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [32, 31],
      },
      {
        date: '11/21/1998',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [17, 34],
      },
      {
        date: '11/7/1998',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [34, 9],
      },
      {
        date: '10/31/1998',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [24, 28],
      },
      {
        date: '11/21/1998',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [10, 3],
      },
    ],
  },
  '1999': {
    results: [
      {
        date: '10/16/1999',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [17, 0],
        notes: ['California Forfeits'],
      },
      {
        date: '10/30/1999',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [7, 17],
        notes: ['California Forfeits'],
      },
      {
        date: '11/20/1999',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [7, 17],
      },
      {
        date: '10/23/1999',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [35, 31],
      },
      {
        date: '9/25/1999',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [32, 42],
      },
      {
        date: '11/20/1999',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [13, 31],
      },
    ],
  },
  '2000': {
    results: [
      {
        date: '10/14/2000',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [38, 46],
      },
      {
        date: '10/28/2000',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [28, 16],
      },
      {
        date: '11/18/2000',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [38, 35],
      },
      {
        date: '10/21/2000',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [30, 32],
      },
      {
        date: '11/4/2000',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [35, 37],
      },
      {
        date: '11/18/2000',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [36, 30],
      },
    ],
  },
  '2001': {
    results: [
      {
        date: '10/20/2001',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [17, 56],
      },
      {
        date: '11/10/2001',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [55, 14],
      },
      {
        date: '11/17/2001',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [0, 27],
      },
      {
        date: '9/29/2001',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [21, 16],
      },
      {
        date: '10/27/2001',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [28, 38],
      },
      {
        date: '11/17/2001',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [28, 35],
      },
    ],
  },
  '2002': {
    results: [
      {
        date: '10/19/2002',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [12, 17],
      },
      {
        date: '10/12/2002',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [28, 30],
      },
      {
        date: '11/23/2002',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [52, 21],
      },
      {
        date: '11/9/2002',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [49, 17],
      },
      {
        date: '10/26/2002',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [18, 28],
      },
      {
        date: '11/23/2002',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [7, 30],
      },
    ],
  },
  '2003': {
    results: [
      {
        date: '10/18/2003',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [20, 23],
      },
      {
        date: '9/27/2003',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [31, 34],
      },
      {
        date: '11/22/2003',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [22, 47],
      },
      {
        date: '10/11/2003',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [21, 44],
      },
      {
        date: '11/1/2003',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [14, 21],
      },
      {
        date: '11/22/2003',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [28, 16],
      },
    ],
  },
  '2004': {
    results: [
      {
        date: '10/16/2004',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [28, 45],
      },
      {
        date: '10/9/2004',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [17, 23],
      },
      {
        date: '12/4/2004',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [29, 24],
        notes: ['USC Vacated Game'],
      },
      {
        date: '9/25/2004',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [31, 28],
      },
      {
        date: '10/30/2004',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [0, 21],
      },
      {
        date: '11/20/2004',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [6, 41],
      },
    ],
  },
  '2005': {
    results: [
      {
        date: '10/8/2005',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [40, 47],
      },
      {
        date: '11/12/2005',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [35, 10],
        notes: ['USC Vacated Game'],
      },
      {
        date: '12/3/2005',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [19, 66],
        notes: ['USC Vacated Game'],
      },
      {
        date: '11/5/2005',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [21, 51],
        notes: ['USC Vacated Game'],
      },
      {
        date: '10/29/2005',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [30, 27],
      },
      {
        date: '11/19/2005',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [27, 3],
      },
    ],
  },
  '2006': {
    results: [
      {
        date: '11/4/2006',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [24, 38],
      },
      {
        date: '11/18/2006',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [9, 23],
      },
      {
        date: '12/2/2006',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [9, 13],
      },
      {
        date: '11/4/2006',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [42, 0],
      },
      {
        date: '9/30/2006',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [0, 31],
      },
      {
        date: '12/2/2006',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [17, 26],
      },
    ],
  },
  '2007': {
    results: [
      {
        date: '10/20/2007',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [21, 30],
      },
      {
        date: '11/10/2007',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [24, 17],
      },
      {
        date: '12/1/2007',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [7, 24],
      },
      {
        date: '10/6/2007',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [24, 23],
      },
      {
        date: '9/1/2007',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [45, 17],
      },
      {
        date: '12/1/2007',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [13, 20],
      },
    ],
  },
  '2008': {
    results: [
      {
        date: '10/25/2008',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [20, 41],
      },
      {
        date: '11/8/2008',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [3, 17],
      },
      {
        date: '12/6/2008',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [28, 7],
      },
      {
        date: '11/15/2008',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [45, 23],
      },
      {
        date: '10/18/2008',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [20, 23],
      },
      {
        date: '11/22/2008',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [16, 37],
      },
    ],
  },
  '2009': {
    results: [
      {
        date: '10/17/2009',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [45, 26],
      },
      {
        date: '10/3/2009',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [30, 3],
      },
      {
        date: '11/28/2009',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [7, 28],
      },
      {
        date: '11/14/2009',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [55, 21],
      },
      {
        date: '10/3/2009',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [16, 24],
      },
      {
        date: '11/21/2009',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [34, 28],
      },
    ],
  },
  '2010': {
    results: [
      {
        date: '10/9/2010',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [7, 35],
      },
      {
        date: '10/16/2010',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [14, 48],
      },
      {
        date: '12/4/2010',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [28, 14],
      },
      {
        date: '10/9/2010',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [35, 37],
      },
      {
        date: '9/11/2010',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [35, 0],
      },
      {
        date: '11/20/2010',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [48, 14],
      },
    ],
  },
  '2011': {
    results: [
      {
        date: '10/29/2011',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [14, 31],
      },
      {
        date: '10/13/2011',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [30, 9],
      },
      {
        date: '11/26/2011',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [0, 50],
      },
      {
        date: '10/29/2011',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [56, 48],
      },
      {
        date: '10/1/2011',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [19, 45],
      },
      {
        date: '11/19/2011',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [28, 31],
      },
    ],
  },
  '2012': {
    results: [
      {
        date: '10/6/2012',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [17, 43],
      },
      {
        date: '9/22/2012',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [9, 27],
      },
      {
        date: '11/17/2012',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [28, 38],
      },
      {
        date: '9/15/2012',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [14, 21],
      },
      {
        date: '11/30/2012',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [24, 27],
        notes: ['Pac-12 Championship Game'],
      },
      {
        date: '11/24/2012',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [35, 17],
      },
      {
        date: '10/20/2012',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [21, 3],
      },
    ],
  },
  '2013': {
    results: [
      {
        date: '10/12/2013',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [10, 37],
      },
      {
        date: '11/9/2013',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [62, 28],
      },
      {
        date: '11/30/2013',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [35, 14],
      },
      {
        date: '11/16/2013',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [17, 20],
      },
      {
        date: '10/19/2013',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [10, 24],
      },
      {
        date: '11/23/2013',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [13, 63],
      },
    ],
  },
  '2014': {
    results: [
      {
        date: '10/18/2014',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [36, 34],
      },
      {
        date: '11/13/2014',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [30, 38],
      },
      {
        date: '11/22/2014',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [20, 38],
      },
      {
        date: '9/6/2014',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [13, 10],
      },
      {
        date: '11/28/2014',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [31, 10],
      },
      {
        date: '11/22/2014',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [38, 17],
      },
    ],
  },
  '2015': {
    results: [
      {
        date: '10/22/2015',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [24, 40],
      },
      {
        date: '10/31/2015',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [27, 21],
      },
      {
        date: '11/28/2015',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [21, 40],
      },
      {
        date: '12/5/2015',
        neutralSite: true,
        teams: ['stanford', 'usc'],
        score: [41, 22],
        notes: ['Pac-12 Championship Game at Santa Clara, CA'],
      },
      {
        date: '9/19/2015',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [41, 31],
      },
      {
        date: '10/15/2015',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [35, 56],
      },
      {
        date: '11/21/2015',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [22, 35],
      },
    ],
  },
  '2016': {
    results: [
      {
        date: '11/26/2016',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [10, 36],
      },
      {
        date: '10/27/2016',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [24, 45],
      },
      {
        date: '11/19/2016',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [36, 14],
      },
      {
        date: '9/17/2016',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [10, 27],
      },
      {
        date: '9/24/2016',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [22, 13],
      },
      {
        date: '11/19/2016',
        neutralSite: false,
        teams: ['stanford', 'cal'],
        score: [45, 31],
      },
    ],
  },
  '2017': {
    results: [
      {
        date: '11/24/2017',
        neutralSite: false,
        teams: ['cal', 'ucla'],
        score: [27, 30],
      },
      {
        date: '9/23/2017',
        neutralSite: false,
        teams: ['usc', 'cal'],
        score: [30, 20],
      },
      {
        date: '11/18/2017',
        neutralSite: false,
        teams: ['ucla', 'usc'],
        score: [23, 28],
      },
      {
        date: '12/1/2017',
        neutralSite: true,
        teams: ['stanford', 'usc'],
        score: [28, 31],
        notes: ['Pac-12 Championship Game at Santa Clara, CA'],
      },
      {
        date: '9/9/2017',
        neutralSite: false,
        teams: ['stanford', 'usc'],
        score: [24, 42],
      },
      {
        date: '9/23/2017',
        neutralSite: false,
        teams: ['ucla', 'stanford'],
        score: [34, 58],
      },
      {
        date: '11/18/2017',
        neutralSite: false,
        teams: ['cal', 'stanford'],
        score: [14, 17],
      },
    ],
  },
  '2018': {
    results: [
      {
        date: '10/13/2018',
        neutralSite: false,
        teams: ['ucla', 'cal'],
        score: [37, 7],
      },
      {
        date: '11/10/2018',
        neutralSite: false,
        teams: ['cal', 'usc'],
        score: [15, 14],
      },
      {
        date: '11/17/2018',
        neutralSite: false,
        teams: ['usc', 'ucla'],
        score: [27, 34],
      },
      {
        date: '9/8/2018',
        neutralSite: false,
        teams: ['usc', 'stanford'],
        score: [3, 17],
      },
      {
        date: '11/24/2018',
        neutralSite: false,
        teams: ['stanford', 'ucla'],
        score: [49, 42],
      },
      {
        date: '12/1/2018',
        teams: ['stanford', 'cal'],
        neutralSite: false,
        score: [23, 13],
      },
    ],
  },
  '2019': {
    results: [
      {
        date: '9/7/2019',
        teams: ['stanford', 'usc'],
        score: [20, 45],
      },
      {
        date: '10/17/2019',
        teams: ['ucla', 'stanford'],
        score: [34, 16],
      },
      {
        date: '11/16/2019',
        teams: ['usc', 'cal'],
        score: [41, 17],
      },
      {
        date: '11/23/2019',
        teams: ['cal', 'stanford'],
        score: [24, 20],
      },
      {
        date: '11/23/2019',
        teams: ['ucla', 'usc'],
        score: [35, 52],
      },
      {
        date: '11/30/2019',
        teams: ['cal', 'ucla'],
        score: [28, 18],
      },
    ],
  },
  '2020': {
    results: [
      {
        date: '12/20/2020',
        teams: ['stanford', 'ucla'],
        score: [48, 47],
      },
      {
        date: '11/27/2020',
        teams: ['stanford', 'cal'],
        score: [24, 23],
      },
      {
        date: '11/15/2020',
        teams: ['cal', 'ucla'],
        score: [10, 34],
      },
      {
        date: '12/12/2020',
        teams: ['usc', 'ucla'],
        score: [43, 38],
      },
    ],
  },
  '2021': {
    results: [
      {
        date: '9/11/2021',
        teams: ['stanford', 'usc'],
        score: [42, 28],
      },
      {
        date: '9/25/2021',
        teams: ['ucla', 'stanford'],
        score: [35, 24],
      },
      {
        date: '11/20/2021',
        teams: ['cal', 'stanford'],
        score: [41, 11],
      },
      {
        date: '11/20/2021',
        teams: ['ucla', 'usc'],
        score: [62, 33],
      },
      {
        date: '11/27/2021',
        teams: ['cal', 'ucla'],
        score: [14, 42],
      },
      {
        date: '12/4/2021',
        teams: ['usc', 'cal'],
        score: [14, 24],
      },
    ],
  },
  '2022': {
    results: [
      {
        date: '9/10/2022',
        teams: ['usc', 'stanford'],
        score: [41, 28],
      },
      {
        date: '10/29/2022',
        teams: ['stanford', 'ucla'],
        score: [13, 38],
      },
      {
        date: '11/5/2022',
        teams: ['cal', 'usc'],
        score: [35, 41],
      },
      {
        date: '11/19/2022',
        teams: ['usc', 'ucla'],
        score: [48, 45],
      },
      {
        date: '11/19/2022',
        teams: ['stanford', 'cal'],
        score: [20, 27],
      },
      {
        date: '11/25/2022',
        teams: ['ucla', 'cal'],
        score: [35, 28],
      },
    ],
  },
};
