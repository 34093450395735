import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Link, Redirect, Route} from 'react-router-dom';

import HistoryPage from './HistoryPage';
import ScrollToTop from './ScrollToTop';
import YearPage from './YearPage';

import './styles.css';

const App = () => (
  <Router>
    <ScrollToTop>
      <div className="App">
        <h1>Kings of California</h1>
        <div className="allSeasons">
          <Link to="/history">All seasons</Link>
        </div>
        <Route path="/history" component={HistoryPage} />
        <Route path="/years/:year" component={YearPage} />
        <Route exact path="/" render={() => <Redirect to="/years/2022" />} />
      </div>
    </ScrollToTop>
  </Router>
);

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
