const generateStandings = results => {
  return results.reduce((accum, result) => {
    const {teams, score} = result;
    const [awayTeam, homeTeam] = teams;
    const [awayScore, homeScore] = score;
    const isTie = homeScore === awayScore;
    const homeWinner = !isTie && homeScore > awayScore;

    const {
      wins: awayWins,
      losses: awayLosses,
      ties: awayTies,
      winsAgainst: awayWinsAgainst,
      pointsFor: pointsForAway,
      pointsAgainst: pointsAgainstAway,
    } = accum[awayTeam] || {
      wins: 0,
      losses: 0,
      ties: 0,
      winsAgainst: {},
      pointsFor: 0,
      pointsAgainst: 0,
    };
    const {
      wins: homeWins,
      losses: homeLosses,
      ties: homeTies,
      winsAgainst: homeWinsAgainst,
      pointsFor: pointsForHome,
      pointsAgainst: pointsAgainstHome,
    } = accum[homeTeam] || {
      wins: 0,
      losses: 0,
      ties: 0,
      winsAgainst: {},
      pointsFor: 0,
      pointsAgainst: 0,
    };

    return {
      ...accum,
      [awayTeam]: {
        wins: homeWinner ? awayWins : isTie ? awayWins : awayWins + 1,
        losses: homeWinner ? awayLosses + 1 : awayLosses,
        ties: isTie ? awayTies + 1 : awayTies,
        winsAgainst: homeWinner
          ? awayWinsAgainst
          : {...awayWinsAgainst, [homeTeam]: true},
        pointsFor: pointsForAway + awayScore,
        pointsAgainst: pointsAgainstAway + homeScore,
      },
      [homeTeam]: {
        wins: homeWinner ? homeWins + 1 : homeWins,
        losses: homeWinner ? homeLosses : isTie ? homeLosses : homeLosses + 1,
        ties: isTie ? homeTies + 1 : homeTies,
        winsAgainst: homeWinner
          ? {...homeWinsAgainst, [awayTeam]: true}
          : homeWinsAgainst,
        pointsFor: pointsForHome + homeScore,
        pointsAgainst: pointsAgainstHome + awayScore,
      },
    };
  }, {});
};

export default generateStandings;
