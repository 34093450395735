import * as React from 'react';
import classNames from 'classnames';

const TeamName = props => {
  const {team, emphasized} = props;
  const className = classNames({
    teamNameCal: team === 'cal',
    teamNameStanford: team === 'stanford',
    teamNameUcla: team === 'ucla',
    teamNameUsc: team === 'usc',
    emphasized,
  });

  return <span className={className}>{team.toUpperCase()}</span>;
};

export default TeamName;
